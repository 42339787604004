import React from 'react'
import styled from 'styled-components'



const Navbar = () => {
  return (
    <div id="navbar-login">
     Get Started With the Best ChatRoom for free  !
    </div>
  )
}

export default Navbar;

import firebase from "firebase/app";
import "firebase/auth";

export const auth = firebase.initializeApp ({
    apiKey: "AIzaSyBNNdlcRqre1Ct8mM2e-JklH5MT5klVV98",
    authDomain: "erechat-f7b22.firebaseapp.com",
    projectId: "erechat-f7b22",
    storageBucket: "erechat-f7b22.appspot.com",
    messagingSenderId: "604836256625",
    appId: "1:604836256625:web:bf5c0003da6d81afc134d0"
  }).auth;


import React from 'react';
import {InstagramOutlined,GoogleOutlined , FacebookOutlined } from '@ant-design/icons';
import "firebase/app";
import firebase from 'firebase';
import styled from 'styled-components';
import {Box ,Stack, Typography,Button } from '@mui/material';
import { auth } from '../firebase' ;
import Navbar from './Navbar';
import Logo from '../images/Logo-1.png';


 const Login = () => {
    return (
           
        <div id="login-page">
                 <Typography color="#8A2BE2" fontWeight="800" sx={{ fontSize: {lg: '94px' , xs: '50px'},
                   marginLeft:{lg: '40px' , xs: '20px'}, marginTop:{lg: "100px" , xs: "50px"} }}   >
                    Erechat 
                </Typography>
              <Typography  fontWeight={700}
                    sx={{ fontSize: {lg: '44px' , xs: '30px'},
                    marginLeft:{lg: '40px' , xs: '20px'}
                    }}
                    mb="23px" mt="30px">
                    Stay Connected With your people !
                    </Typography>
            <div id="login-card">
                <h2>Welcome to Erechat !</h2>

                <div className='login-button google'
                onClick={() => firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider())}
                >
                    <GoogleOutlined/>  Sign In with Google
                </div>
                <br/> <br/>

                <div className='login-button facebook'
                onClick={() => firebase.auth().signInWithRedirect(new firebase.auth.FacebookAuthProvider())}
                >
                  <FacebookOutlined/> Sign In with Facebook
                  </div>
             
            </div>
        </div>
    );
}

export default Login;
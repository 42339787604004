import React from 'react';
import Navbar from '../components/Navbar';
import Footer from './Footer';
import Login from './Login';
import {Box ,Stack, Typography,Button } from '@mui/material';

const Home = () => {
  return (
    <div>
        <Box>
       <Navbar/>
      <Login/>
        </Box>
      
    </div>
  )
}

export default Home
